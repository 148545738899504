// LAYOUT
// ----------------------------------------------------------------------


export const formatDateUser = 'MM/DD/YYYY';
export const formatDateTimeUser = 'MM/DD/YYYY HH:mm';

export const formatDateSystem = 'YYYY-MM-DD';
export const formatDateTimeSystem = 'YYYY-MM-DD HH:mm';

export const getFormatDateTime = (locale) => {
  return locale == 'en-US' ? import.meta.env.VITE_FORMAT_US_DATETIME : import.meta.env.VITE_FORMAT_ALL_DATETIME;
};



export const HEADER = {
  MOBILE_HEIGHT: 10,
  MAIN_DESKTOP_HEIGHT: 0,
  DASHBOARD_DESKTOP_HEIGHT: 0,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 0,
};


export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 24,
  DASHBOARD_ITEM_SUB_HEIGHT: 24,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 24,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'purple',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const config = {
		username: process.env.REACT_APP_DATABASE_USERNAME,
		password: process.env.REACT_APP_DATABASE_PASSWORD,
		database: process.env.REACT_APP_DATABASE_NAME,
		host: process.env.REACT_APP_DATABASE_HOST,
		dialect: process.env.REACT_APP_DATABASE_DIALECT,
		port: process.env.REACT_APP_DATABASE_PORT,
    api: process.env.REACT_APP_API_URL_FULL
};

export const API = {
  URL: process.env.REACT_APP_API_URL,
  VERSION: process.env.REACT_APP_API_VERSION,
  URL_FULL: process.env.REACT_APP_API_URL_FULL,
  LOCALIZACION: process.env.REACT_APP_API_LOCALIZACION,
  ORIGEN: process.env.REACT_APP_API_ORIGEN,
  CLIENTE: process.env.REACT_APP_API_CLIENTE,
  OFERTA: process.env.REACT_APP_API_OFERTA,
  RESERVA: process.env.REACT_APP_API_RESERVA,
  CONTRATO: process.env.REACT_APP_API_CONTRATO,
  PERFIL: process.env.REACT_APP_API_PERFIL,
  USUARIO: process.env.REACT_APP_API_USUARIO,
  VEHICULO: process.env.REACT_APP_API_VEHICULO,
};
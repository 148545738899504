import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession  } from '../../utils/jwt';
// ----------------------------------------------------------------------
import moment from 'moment'

const initialState = {
  isLoading: false,
  error: null,
  vehiculos: [],
  vehiculosDisponible: [],
  vehiculosServio: [],
  fecha: moment().format("YYYY-MM-DD")
};

const slice = createSlice({
  name: 'turno',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getVehiculosSuccess(state, action) {
      state.isLoading = false;
      state.vehiculos = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getVehiculosSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function getVehiculos() {
  const accessToken = window.localStorage.getItem('gramaloteToken');
  axios.defaults.headers.common['autorizacion_token'] = accessToken;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const parametro = {inicio: moment().set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD HH:mm:ss"), termino: moment().format("YYYY-MM-DD HH:mm:ss")}
        const response = await axios.get('/programacion/',{params: parametro});
        dispatch(slice.actions.getVehiculosSuccess(response.data.events));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVehiculosDisponible() {
  const accessToken = window.localStorage.getItem('gramaloteToken');
  axios.defaults.headers.common['autorizacion_token'] = accessToken;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const parametro = {inicio: moment().set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD HH:mm:ss"), termino: moment().format("YYYY-MM-DD HH:mm:ss")}
        const response = await axios.get('/programacion/',{params: parametro});
        dispatch(slice.actions.getVehiculosSuccess(response.data.events));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { TextField, Checkbox } from '@mui/material';

// hooks
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Notificacion } from '../../components/Util/Notificacion'
import useModels from "react-use-models"
import { useValidator } from "react-joi"

// ----------------------------------------------------------------------
const Joi = require('joi').extend(require('@joi/date'));

const LoginForm = () => {
  const { login } = useAuth();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);

  const { models, updateModel, register } = useModels({
    defaultState: {
      email: "",
      password: ""
    },
  })
  const reset = () => {
    updateModel("email", "");
    updateModel("password", "");
  }

  const { state, setData, validate } = useValidator({
    initialData: models,
    schema: Joi.object({
      email: Joi.string().required(),
      password: Joi.string().required()
    }),
  })
  useEffect(() => {   
    if(!isLoading)setIsLoading(true);
  }, [])

  useEffect(() => {   
    if(isLoading){
      setData(models);   
      validate();
    }
  }, [models])


  const onSubmit = async () => {
    try {   
      validate();
      if (state.$validation_success) {
        const validacion = await login(models.email, models.password).then().catch(err => {
          reset();
          Notificacion(false, err);
        });
        if(validacion){
          if (validacion.status === 200) {
            navigate(`/dashboard/locations`)
          }
        }
      } else {
        Notificacion(false, "Validar datos, por favor");
      }
    } catch (error) {
      Notificacion(false, error);
      reset();
    }
  };

  return (
    <>
      <form>
        <Stack spacing={3}>
          <TextField

            helperText={state.$errors.email.length > 0 ? state.$errors.email.map((data) => data.$message).join(",") : "Ingrese Email"}
            error={state.$errors.email.length > 0 ? true : false}
            {...register.input({ name: "email" })}
            name="email" label="Dirección de Correo" />
          <TextField
            {...register.input({ name: "password" })}
            helperText={state.$errors.password.length > 0 ? state.$errors.password.map((data) => data.$message).join(",") : "Ingrese Contraseña"}
            error={state.$errors.password.length > 0 ? true : false}
            name="password"
            label="Contraseña"
            type='password'
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') onSubmit();
            }}
          />
        </Stack>

        <LoadingButton fullWidth size="large" onClick={onSubmit} variant="contained" >
          Acceder
        </LoadingButton>
      </form>
    </>
  );
}
export { LoginForm };
import {toast } from 'react-toastify';

export const Notificacion = (estado, mensaje) => {
    if(estado){
        toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        });
    } else { 
        toast.error('Error: '+ mensaje, {
            position: "top-right",
            autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
  };
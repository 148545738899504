import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession  } from '../../utils/jwt';

// ----------------------------------------------------------------------
import moment from 'moment'
const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id == event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  const accessToken = window.localStorage.getItem('gramaloteToken');
  axios.defaults.headers.common['autorizacion_token'] = accessToken;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const parametro = {inicio: moment("2022-01-01").set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD HH:mm:ss"), termino: moment("2023-12-31").set({hour:23,minute:59,second:0,millisecond:0}).format("YYYY-MM-DD HH:mm:ss")}
        const response = await axios.get('/programacion/',{params: parametro});
                dispatch(slice.actions.getEventsSuccess(response.data.events));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  const accessToken = window.localStorage.getItem('gramaloteToken');
  axios.defaults.headers.common['autorizacion_token'] = accessToken;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axios.post('/programacion/', newEvent);
        dispatch(slice.actions.createEventSuccess(response.data.programacion));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  const accessToken = window.localStorage.getItem('gramaloteToken');
  axios.defaults.headers.common['autorizacion_token'] = accessToken;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axios.put('/programacion/' + eventId, updateEvent);
        dispatch(slice.actions.updateEventSuccess(response.data.programacion));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  const accessToken = window.localStorage.getItem('gramaloteToken');

  axios.defaults.headers.common['autorizacion_token'] = accessToken;

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        await axios.delete('/programacion/' + eventId);
        dispatch(slice.actions.deleteEventSuccess({ eventId }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}

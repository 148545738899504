import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/AuthGuard'
import AuthGuard from '../guards/AuthGuard'
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {  
  const { user,logout } = useAuth();

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
              <Register />
          ),
        },
        
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        /*{ path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },*/
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/localidad" replace />,
    },
    {
      path: '/dashboard',
      element:  (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/localidad" replace />, index: true },
        { path: 'cliente', element: <Cliente /> },
        { path: 'localidad', element: <Localidad /> },
        { path: 'oferta', element: <Oferta /> },
        { path: 'vehiculo', element: <Vehiculo /> },
        { path: 'reserva', element: <Reserva /> },
        { path: 'reserva/crear', element: <ReservaCrear/> },
        { path: 'reserva/editar/:id', element: <ReservaEditar/> },

        { path: 'contrato', element: <Contrato /> },
        { path: 'contrato/crear', element: <ContratoCrear/> },
        { path: 'contrato/editar/:id', element: <ContratoEditar/> },

        { path: 'perfil', element: <Perfil /> },
        { path: 'usuario', element: user?.roles?.map(rol => rol.rolId == '1')[0] ? <Usuario /> : <NotFound />},
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Autentificacion
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));

// Dashboard
const Localidad = Loadable(lazy(() => import('../pages/Localidad')));
const Cliente = Loadable(lazy(() => import('../pages/Cliente')));
const Oferta = Loadable(lazy(() => import('../pages/Oferta')));
const Reserva = Loadable(lazy(() => import('../pages/Reserva')));
const ReservaCrear = Loadable(lazy(() => import('../pages/reserva/Create')));
const ReservaEditar = Loadable(lazy(() => import('../pages/reserva/Editar')));

const Contrato = Loadable(lazy(() => import('../pages/Contrato')));
const ContratoCrear = Loadable(lazy(() => import('../pages/contrato/Create')));
const ContratoEditar = Loadable(lazy(() => import('../pages/contrato/Editar')));
const Vehiculo = Loadable(lazy(() => import('../pages/Vehiculo')));

const Usuario = Loadable(lazy(() => import('../pages/Usuario')));
const Perfil = Loadable(lazy(() => import('../pages/Perfil')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));



// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  booking: getIcon('ic_booking'),
  turno: getIcon('ic_turno'), 
  kilometraje: getIcon('ic_dashboard'),
};

const sidebarConfigConductor = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'Counter',
      items: [
        { title: 'Reservas', path: '/dashboard/reserva', icon: ICONS.booking },
        { title: 'Contratos', path: '/dashboard/contrato', icon: ICONS.booking }
      ]
    },
    {
      subheader: 'Mantenimiento',
      items: [
        { title: 'Clientes', path: '/dashboard/cliente', icon: ICONS.turno },
        { title: 'Localidades', path: '/dashboard/localidad', icon: ICONS.turno },
        { title: 'Ofertas', path: '/dashboard/oferta', icon: ICONS.vehiculo },
        { title: 'Vehiculos', path: '/dashboard/vehiculo', icon: ICONS.user }
      ]
    },
    {
      subheader: 'Administrador',
      items: [{ title: 'Usuarios', path: '/dashboard/usuario', icon: ICONS.user }]
    }
  ];
export default sidebarConfigConductor;
import jwt_decode from "jwt-decode";
import axios from "axios";
//import { verify, sign } from 'jsonwebtoken';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwt_decode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('gramaloteToken', accessToken);
    axios.defaults.headers.common['autorizacion_token'] = accessToken;

  } else {
    localStorage.removeItem('gramaloteToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, axios };

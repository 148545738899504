// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { HelmetProvider } from 'react-helmet-async';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SnackbarProvider } from 'notistack';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <ThemeProvider>
        <ThemeColorPresets>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ScrollToTop />
              <ToastContainer style={{ zIndex: 999999 }} />
              <Router />
            </MotionLazyContainer>
          </LocalizationProvider>
        </ThemeColorPresets>
      </ThemeProvider>
    </SnackbarProvider>

  );
}
